const LOGIN_STATUS = Object.freeze({
	DEFAULT: Symbol('DEFAULT'),
	LOADING: Symbol('LOADING'),
	SUCCESS: Symbol('SUCCESS'),
});

const USER_GROUP = Object.freeze({
	ADMIN: 99,
	DEPT: 50,
	TEACHER: 10,
});

const SCORE_STATUS = Object.freeze({
	TEMP: 0,
	CHECKED_SUCCESS: 1,
	CHECKED_FAILED: 2,
	FORCE_SENT: 3,
});

const STUDENT_SITUATION = Object.freeze({
	NORMAL: 0,
	LACK_DATA: -10,
	NO_DATA: -20,
	MISSING: -30,
	REFUND: -40,
	UNQUALIFIED: -50,
});

export { LOGIN_STATUS, USER_GROUP, SCORE_STATUS, STUDENT_SITUATION };
